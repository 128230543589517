
export default defineNuxtRouteMiddleware((to, from) => {
    const orderID = to.query.order_id || to.query.orderID;
    if (orderID) {
        return;
    } else {
        if (process.client) {
            alert('無此訂單號');
        }
        return navigateTo('/');
    }
});
